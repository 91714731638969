@import 'src/styles/variables';
@import 'src/styles/mixins';

.banner {
  position: relative;
  max-width: 1368px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 640px;
  padding: 20px 20px 24px 20px;

  @include vp-435 {
    height: 450px;
    width: 349px;
  }

  @media (max-width: 400px) {
    height: 410px;
    width: 318px;
  }

  .button {
    margin-top: 24px;

    @include vp-435 {
      margin-top: 16px;
    }
  }
}

.gallery {
  @include vp-435 {
    border-radius: 0px 0px 32px 32px;
    height: 440px;
    width: 100%;
  }
}

.title {
  color: $white;
  overflow: hidden;
  text-overflow: ellipsis;
  @include text(24px, 500, 32px);
  margin-top: 16px;
  display: -webkit-box;
  white-space: wrap;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  @include vp-435 {
    @include text(16px, 500, 22px);
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
}

.arrowButton {
  margin-top: 10px;
}

.img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
  border-radius: 16px;
}

.info {
  width: 100%;
}

.tag {
  margin-bottom: 16px;
}

.titleBig {
  @include text(48px, 800, 48px);
  color: $white;

  @include vp-767 {
    @include text(36px, 800, 38px);
  }
}

.price {
  @include text(24px, 700, 20px);
  color: $white;
  white-space: nowrap;
  position: absolute;
  bottom: 32px;
  right: 20px;

  @include vp-435 {
    @include text(16px, 500, 20px);
  }
}

.clickable {
  cursor: pointer;
}

.backdrop {
  border-radius: 0 0 16px 16px;
}
